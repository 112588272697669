<template>
  <div>
    <h1>Error 404</h1>
    <h1>File not found</h1>
  </div>
</template>

<script>
export default {
  name: "filenotfound",
  metaInfo: {
    title: "filenotfound",
    titleTemplate: "CPC - %s",
    meta: [
      {
        name: `description`,
        content: `Filenotfound.`
      }
    ],
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  }
};
</script>
